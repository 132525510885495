import React, { useEffect, useState } from "react";
import HomeBanner from "../components/home/HomeBannerNew";
import OurMission from "../components/home/OurMission";
import WhyChooseUs from "../components/home/WhyChooseUs";
import ServiceOverview from "../components/home/ServiceOverview";
import HomeServices from "../components/home/HomeServices";
import CoreValue from "../components/home/CoreValue";
// import HomeServices from "../components/home/HomeServices";

const IndexPage = () => {
  return (
    <>
      <HomeBanner />
      <HomeServices />
      <OurMission />
      <CoreValue />
      <WhyChooseUs />
      <ServiceOverview />
    </>
  );
};

export default IndexPage;
