import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./style.module.scss";
import { Link } from "react-router-dom";

const CoreValue = () => {
  const CoreValue = [
    {
      title: "Integrity",
      subtitle:
        "We conduct our operations transparently and ethically,fostering trust and accountability in every client relationship",
    },
    {
      title: "Excellence",
      subtitle:
        ": We pursue the highest standards in all areas, from logistics planning to final delivery, ensuring quality service every step of the way.",
    },
    {
      title: "Customer-Centricity:",
      subtitle:
        ": Our clients’ needs drive our solutions. We actively listen and adapt to provide the most responsive and tailored service possible.",
    },
    {
      title: "Innovation",
      subtitle:
        "We embrace technological advancements and continually improve our processes to enhance efficiency and meet the evolving demands of the logistics industry.",
    },
    {
      title: "Sustainability",
      subtitle:
        "We are committed to eco-friendly practices that minimize our environmental footprint, implementing sustainable logistics to protect our planet.",
    },
  ];

  return (
    <section className={` ${styles.CoreValue}`}>
      <Container>
        <h2 className={`${styles.h2}`}>Core Values</h2>
        <Row className={` ${styles.row}`}>
          <Col sx={12} md={6} className={`${styles.colLeft}`}>
            {CoreValue.map((section, index) => (
              <div key={index}>
                <h3>{section.title}</h3>
                <p>{section.subtitle}</p>
              </div>
            ))}
          </Col>
          <Col sx={12} md={6} className={`${styles.colRight}`}>
            <img className={`${styles.img}`} src="/images/core.jpg" alt="img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CoreValue;
