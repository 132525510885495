import React, { useState, useRef, useEffect } from "react";
import { Navbar, Nav, Button, Offcanvas, Container } from "react-bootstrap";
import styles from "./style.module.scss";
import { Dropdown } from "react-bootstrap";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loginAsync, logout } from '../../../redux/store';

const Header = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState('');
  const [authToken, setAuthToken] = useState(localStorage.getItem('jwt'));
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('user') || '{}'));
  const dispatch = useDispatch();

  // Add this effect to check auth status and user info


  const { isLoggedIn, user, status, error } = useSelector((state) => state.auth);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSearchModalClose = () => setShowSearchModal(false);
  const handleSearchModalShow = () => setShowSearchModal(true);

  const [isOpen, setIsOpen] = useState(false);
  const searchRef = useRef(null);

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const handleLogout = async () => {
    await dispatch(logout());
    navigate('/login')

  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
console.log('userasaasassaasas',user)
  return (
    <>
      <Navbar variant="light" expand="lg" className={`${styles.header}`}>
        <Container>
          <Link to="/">
            <img
              src="/images/new.png"
              className="d-inline-block align-center"
              alt="Logo"
              width={150}
              height={70}
            />
          </Link>
          <div className="MobSearch">
            <Navbar.Toggle
              aria-controls="offcanvasNavbar"
              onClick={handleShow}
            />
          </div>
          <Navbar.Collapse
            id="navbarScroll"
            className="d-none d-lg-flex justify-content-end align-items-center"
          >
            <Nav
              className={`d-flex justify-content-center align-items-center ${styles.nav}`}
            >
              <Link className={`${styles.link}`} to="/">
                Home
              </Link>
              <Link className={`${styles.link}`} to="/about-us/">
                About Us
              </Link>
              <Link className={`${styles.link}`} to="/services/">
                Services
              </Link>
              <Link className={`${styles.link}`} to="/destination/">
                Destination
              </Link>
              {/* <Link className={`${styles.link}`} to="/shipping-calculator/">
                Shipping Calculator
              </Link> */}
            </Nav>
            <Link className={styles.formWrap} to="/contact-us/">
              <Button
                className={`${styles.primary}`}
                type="submit"
                variant="none"
              >
                Contact Us
              </Button>
            </Link>
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                variant="none"
                className={`${styles.primary} d-flex align-items-center gap-2`}
                id="dropdown-basic"
              >
                {isLoggedIn ? (
                  <>
                    <img
                      src="/images/user.png"
                      alt="Profile"
                      className={styles.profileIcon}
                      width={24}
                      height={24}
                    />
                    {user.name}
                  </>
                ) : (
                  'My Account'
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {isLoggedIn ? (
                  <>
                    <Dropdown.Item onClick={() => navigate('/wallet')}>
                      Dashboard
                    </Dropdown.Item>

                    <Dropdown.Item onClick={handleLogout}>
                      Logout
                    </Dropdown.Item>
                  </>
                ) : (
                  <>
                    <Dropdown.Item as={Link} to="/login/">
                      Login
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="/register/">
                      Register
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* Mobile header */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        responsive="lg"
        placement="end"
        className={`d-lg-none d-sm-block ${styles.Offcanvas}`}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link to="/home">
              <img
                src="/images/new.png"
                className="d-inline-block align-center"
                alt="Logo"
                width={147}
                height={49}
              />
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="d-grid flex-grow-1">
            <Link className={`${styles.link}`} to="/" onClick={handleClose}>
              Home
            </Link>
            <Link
              className={`${styles.link}`}
              to="/about-us/"
              onClick={handleClose}
            >
              About Us
            </Link>
            <Link
              className={`${styles.link}`}
              to="/services/"
              onClick={handleClose}
            >
              Services
            </Link>

            <Link
              className={`${styles.link}`}
              to="/destination/"
              onClick={handleClose}
            >
              Destination
            </Link>
            <Link
              className={styles.formWrap}
              to="/contact-us/"
              onClick={handleClose}
            >
              <Button
                className={`${styles.primary}`}
                type="submit"
                variant="none"
              >
                Contact Us
              </Button>
            </Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
