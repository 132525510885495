import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "../style.module.scss";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useSelector, useDispatch } from 'react-redux';
import { loginAsync, logout } from '../../../redux/store';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [errors, setErrors] = useState({
    email: '',
    password: '',
    general: ''
  });

  // Validation function
  const validateForm = () => {
    let tempErrors = {
      email: '',
      password: '',
    };
    let isValid = true;

    // Email validation
    if (!formData.email) {
      tempErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = 'Email is invalid';
      isValid = false;
    }

    // Password validation
    if (!formData.password) {
      tempErrors.password = 'Password is required';
      isValid = false;
    } else if (formData.password.length < 6) {
      tempErrors.password = 'Password must be at least 6 characters';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    number: "",
    age: "",
    password: "",
    confirmPassword: "",
  });
  

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };




  const handleSubmit = async (e) => {
    console.log('formData,form',formData)
    e.preventDefault();
    setErrors({ email: '', password: '', general: '' });

    // Validate form before submission
    if (!validateForm()) {
      return;
    }
    try{

     const user = await dispatch(loginAsync({ identifier:formData.email, password:formData.password }));
  
   if(user.meta.requestStatus==="fulfilled"){
    navigate('/wallet');
   }
      // Redirect or handle successful login

    } catch (err) {
      setErrors({
        ...errors,
        general:  'Login failed. Please try again.'
      });
    }
  };

 

  return (
    <section className={`${styles.Register}`}>
      <Container className={`${styles.Container}`}>
        <Row className={`${styles.row}`}>
          <Col sx={12} md={3} lg={3} className={`${styles.colLeft}`}>
            <h2>Hello..</h2>
            <p className={`${styles.linkWarap}`}>
              Don't have an account? Create one in less than a minute.
              <Link to="/register/" className={`${styles.link}`}>
                {""} Create account {""}
              </Link>
            </p>
          </Col>
          <Col sx={12} md={9} lg={5} className={`${styles.colRight}`}>
            <h2>Log in</h2>
            <p>Welcome! Log in to your account below.</p>
            <form onSubmit={handleSubmit}>
            <div>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="email" placeholder="name@example.com" name='email'   value={formData.email}
                    onChange={handleChange}/>
                      {errors.email && <div className="text-danger">{errors.email}</div>}

                </Form.Group>
                <Form.Group className="mb-3" controlId="formPassword">
                  <Form.Label placeholder="Enter Your Password">
                    Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                {errors.general && (
          <div className="text-danger mb-3">
            {errors.general}
          </div>
        )}
                <div className="d-flex justify-content-between my-2">
                  <Form.Check
                    type="radio"
                    label="Remember me"
                    name="radioOptions"
                    value="person"
                    onChange={()=>{}}
                    className="custom-radio"
                  />
                  <Link to="/lost-password/" className={`${styles.formLink}`}>
                    Lost your password?
                  </Link>
                </div>
                {/* Flex buttons with 50% width for previous and submit */}

                <div
                  className="d-flex justify-content-between"
                  style={{ marginTop: "1.5rem" }}
                >
                  <Button
                    type="submit"
                    variant="none"
                    className={`w-100 ${styles.logInBtn}`}
                  >
                    LogIn
                  </Button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Login;
