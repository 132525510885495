import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import styles from "./style.module.scss";
import { GrMapLocation } from "react-icons/gr";
import { RiCalendarScheduleFill } from "react-icons/ri";
import { GoBookmark } from "react-icons/go";
import { FaWpforms } from "react-icons/fa";
import { LuDollarSign } from "react-icons/lu";
import { useSelector, useDispatch } from 'react-redux';


const HomeServices = () => {
  const { isLoggedIn, user, status, error } = useSelector((state) => state.auth);
  console.log('isLoggedIn',isLoggedIn)

  const navigate = useNavigate(); // Hook for programmatic navigation
  const isAuthenticated = false; // Replace this with your authentication logic

  const OurVision = [
    {
      title: "Tracking",
      img: <GrMapLocation />,
      link: "/tracking/",
    },
    {
      title: "Schedules",
      img: <RiCalendarScheduleFill />,
      link: "/schedules/",
    },
    {
      title: "Quotation",
      img: <LuDollarSign />,
      link: "/request-quote/",
    },
    {
      title: "Booking",
      img: <GoBookmark />,
      link: "/booking/",
    },
    {
      title: "Forms",
      img: <FaWpforms />,
      link: "/form/",
    },
  ];
  const handleLinkClick = (link) => {
    if (link === "/booking/" && !isLoggedIn) {
      // If user is not authenticated and tries to access the booking page, redirect to login
      navigate("/login/");
    } else if (link === "/booking/" && isLoggedIn) {
      navigate("/request-quote/");
    } else {
      navigate(link); // Navigate to the link as usual
    }
  };

  return (
    <section className={`${styles.HomeServices}`}>
      <Container className={`${styles.container}`}>
        <Row className={`${styles.row}`}>
          {OurVision.map((service, index) => (
            <Col
              sx={3}
              md={3}
              lg={2}
              className={`${styles.colLeft}`}
              key={index}
            >
              <div
                onClick={() => handleLinkClick(service.link)} // Handle click with custom function
                className={`${styles.link}`}
                style={{ cursor: "pointer" }} // Add pointer cursor for clarity
              >
                <div className={`${styles.linkWrap}`}>
                  <div className={`${styles.imgWrap}`}>
                    <div className={`${styles.img}`}>{service.img}</div>
                  </div>
                  <p className={`${styles.colLeftP}`}>{service.title}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default HomeServices;
