import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./components/layout/index";
import HomePage from "./pages/index";
import AboutUs from "./pages/about-us";
import Services from "./pages/services";
import HowItWorks from "./pages/how-it-works";
import ShippingCalculator from "./pages/shipping-calculator";
import ContactUs from "./pages/contact-us";
import Register from "./pages/register";
import Login from "./pages/login";
import LostPassword from "./components/register/LostPassword";
import Shippment from "./components/Shippment/ShippmentForm";
import ShippingForm from "./components/shippingForm/ShippmentFormDetails";
import RequestQuote from "./components/services/RequestAQuote";
import Tracking from "./components/services/Tracking";
import Schedules from "./components/services/Schedules";
import Booking from "./components/services/Booking";
import Form from "./components/services/Form";
import Wallet from "./components/services/WalletTabs";
import { Navigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { loginAsync, logout } from './redux/store';
function App() {

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us/" element={<AboutUs />} />
          <Route path="/services/" element={<Services />} />
          <Route path="/destination/" element={<HowItWorks />} />
          <Route
            path="/shipping-calculator/"
            element={<ShippingCalculator />}
          />
          <Route path="/contact-us/" element={<ContactUs />} />
          <Route path="/register/" element={<Register />} />
          <Route path="/login/" element={<Login />} />
          <Route path="/lost-password/" element={<LostPassword />} />
          <Route path="/shippment/" element={<Shippment />} />
          <Route path="/shippment/" element={<Shippment />} />
          <Route path="/request-quote/" element={<RequestQuote />} />
          <Route path="/tracking/" element={<Tracking />} />
          <Route path="/schedules/" element={<Schedules />} />
          <Route path="/booking/" element={<Booking />} />
          <Route path="/form/" element={<Form />} />
          <Route 
            path="/wallet/" 
            element={
              <ProtectedRoute>
                <Wallet />
              </ProtectedRoute>
            } 
          />
          
          {/* ... other routes ... */}
                </Routes>
      </Layout>
    </Router>
  );
}
const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, user, status, error } = useSelector((state) => state.auth);

  
  if (!isLoggedIn) {
    // Redirect to login if no token exists
    return <Navigate to="/login" replace />;
  }

  return children;
};
export default App;
