import React, { useState } from "react";
import { Container, Row, Col, Button, Alert } from "react-bootstrap";
import styles from "../style.module.scss";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import countryList from "react-select-country-list";
import axios from "axios";

const Register = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("person");
  const [value, setValue] = useState(null);
  const options = countryList().getData();
  const [step, setStep] = useState(1);

  const [formData, setFormData] = useState({
    accountType: "person",
    country: "",
    title: "Mr",
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    referralSource: "Google",
    reference: "",
    password: "",
    confirmPassword: "",
    balance: 0,
  });

  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState({status:false,message:'',variant:''});

  // Handle input changes
  const handleChange = (e) => {
    console.log(value, "value");
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle country selection
  const handleChangeCountry = (selectedOption) => {
    setValue(selectedOption);
    setFormData({
      ...formData,
      country: selectedOption.label,
    });
  };

  // Handle radio button change
  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
    setFormData({
      ...formData,
      accountType: e.target.value,
    });
  };

  // Validate form
  const validateForm = () => {
    const newErrors = {};
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email is invalid";
    if (!formData.phoneNumber) newErrors.phoneNumber = "Phone number is required";
    if (!formData.password) newErrors.password = "Password is required";
    if (formData.password !== formData.confirmPassword) newErrors.confirmPassword = "Passwords do not match";
    if (!isChecked) newErrors.terms = "You must agree to the terms";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    if (validateForm()) {
      try {
        const response = await axios.post('https://lobster-app-scymd.ondigitalocean.app/api/users', {
          username: formData.email,
         ...formData,
         role:1,
         name:formData.firstName + ' ' + formData.lastName
          // Add other fields as needed by your API
        });
        console.log(response.data);
        setShowAlert({status:true,message:'Registration successful!',variant:'success'});
        setFormData({
          accountType: "person",
          country: "",
          title: "Mr",
          firstName: "",
          lastName: "",
          email: "",
          countryCode: "",
          phoneNumber: "",
          referralSource: "Google",
          reference: "",
          password: "",
          companyName:"",
          confirmPassword: "",
        });
        setValue(null); // Reset country select
        setIsChecked(false); // Reset checkbox
        setStep(1); // Reset to first step
        
        // Reset form or redirect user
      } catch (error) {
        setShowAlert({status:true,message:'Something went wrong!',variant:'danger'});
        console.error('Registration error:', error.response?.data || error.message);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  // Function to move to the next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Function to move to the previous step
  const previousStep = () => {
    setStep(step - 1);
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <section className={`${styles.Register}`}>
      <Container className={`${styles.Container}`}>
        {showAlert.status && (
          <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
            Registration successful!
          </Alert>
        )}
        <Row className={`${styles.row}`}>
          <Col sx={12} md={3} lg={3} className={`${styles.colLeft}`}>
            <h2>Hello..</h2>
            <p className={`${styles.linkWarap}`}>
              Already have account.
              <Link to="/login/" className={`${styles.link}`}>
                {""} Click Here {""}
              </Link>
              to Login
            </p>
          </Col>
          <Col sx={12} md={9} lg={5} className={`${styles.colRight}`}>
            <h2>
              Create Your <span> Account</span>
            </h2>
            <form onSubmit={handleSubmit}>
              {step === 1 && (
                <div className={` ${styles.stepOne}`}>
                  <Form.Group>
                    <div className="d-flex mb-3" style={{ gap: "30px" }}>
                      <Form.Check
                        type="radio"
                        label="Indivisual"
                        name="radioOptions"
                        value="person"
                        checked={selectedOption === "person"}
                        onChange={handleRadioChange}
                        className="custom-radio"
                      />
                      <Form.Check
                        type="radio"
                        label="Company"
                        name="radioOptions"
                        value="organization"
                        checked={selectedOption === "organization"}
                        onChange={handleRadioChange}
                        className="custom-radio"
                      />
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formCountrySelect" className="mb-3">
                    <Form.Label>Country</Form.Label>
                    <Select
                      options={options}
                      value={value}
                      onChange={handleChangeCountry}
                      placeholder="Country"
                    />
                    {errors.country && <Form.Text className="text-danger">{errors.country}</Form.Text>}
                  </Form.Group>
                  {/* Next button for step 1 with 100% width */}
                  <Button
                    type="button"
                    onClick={nextStep}
                    className={`w-100 nextButton ${styles.nextButton}`}
                    style={{ marginTop: "1.5rem" }}
                    variant="none"
                  >
                    Next
                  </Button>
                </div>
              )}

              {step === 2 && (
                <div className={` ${styles.stepTwo}`}>
                  <div className="d-flex mb-3" style={{ gap: "15px" }}>
                    <Form.Group className="w-25">
                      <Form.Label>Title</Form.Label>
                      <Form.Control as="select" size="normal" name="title" value={formData.title} onChange={handleChange}>
                        <option>Mr</option>
                        <option>Ms</option>
                        <option>Mrs</option>
                        <option>Dr</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="w-50">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control type="text" placeholder="First Name" name="firstName" value={formData.firstName} onChange={handleChange} />
                      {errors.firstName && <Form.Text className="text-danger">{errors.firstName}</Form.Text>}
                    </Form.Group>
                    <Form.Group className="w-50">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control type="text" placeholder="Last Name" name="lastName" value={formData.lastName} onChange={handleChange} />
                      {errors.lastName && <Form.Text className="text-danger">{errors.lastName}</Form.Text>}
                    </Form.Group>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      placeholder="Enter Your Company Name"
                    />
                                        {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}

                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" placeholder="Enter Your Email" name="email" value={formData.email} onChange={handleChange} />
                    {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
                  </Form.Group>

                  <div className="d-flex mb-3" style={{ gap: "15px" }}>
                    <Form.Group className="w-30" style={{ width: "30%" }}>
                      <Form.Label>Country Code</Form.Label>
                      <Form.Control type="text" placeholder="+1" name="countryCode" value={formData.countryCode} onChange={handleChange} />
                    </Form.Group>

                    <Form.Group className="w-70" style={{ width: "70%" }}>
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control type="text" placeholder="123-456-7890" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
                      {errors.phoneNumber && <Form.Text className="text-danger">{errors.phoneNumber}</Form.Text>}
                    </Form.Group>
                  </div>

                  <div className="d-flex mb-3" style={{ gap: "15px" }}>
                    <Form.Group className="w-50">
                      <Form.Label>How did you know about us?</Form.Label>
                      <Form.Control as="select" size="normal" name="referralSource" value={formData.referralSource} onChange={handleChange}>
                        <option>Google</option>
                        <option>Social Media</option>
                        <option>Friend</option>
                        <option>Other</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="w-50">
                      <Form.Label>Add Reference</Form.Label>
                      <Form.Control type="text" placeholder="Reference Name" name="reference" value={formData.reference} onChange={handleChange} />
                    </Form.Group>
                  </div>

                  {/* Flex buttons with 50% width for previous and next */}
                  <div
                    className="d-flex justify-content-between"
                    style={{ gap: "15px", marginTop: "2rem" }}
                  >
                    <Button
                      type="button"
                      onClick={previousStep}
                      className={`w-50 prevButton ${styles.prevButton}`}
                      variant="none"
                    >
                      Previous
                    </Button>
                    <Button
                      type="button"
                      onClick={nextStep}
                      className={`w-50 nextButton ${styles.nextButton}`}
                      variant="none"
                    >
                      Next
                    </Button>
                  </div>
                </div>
              )}

              {step === 3 && (
                <div className={` ${styles.stepThree}`}>
                  <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    {errors.password && <Form.Text className="text-danger">{errors.password}</Form.Text>}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formConfirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                    />
                    {errors.confirmPassword && <Form.Text className="text-danger">{errors.confirmPassword}</Form.Text>}
                  </Form.Group>

                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label=""
                      onChange={handleCheckboxChange}
                      className="custom-radio"
                    />
                    {errors.terms && <Form.Text className="text-danger">{errors.terms}</Form.Text>}
                    <p className={`${styles.text}`}>  
    By accessing this site, I confirm that I have read, understood, and agree to the   {""}
    <Link className={`${styles.formLink}`} to="/">   
        Terms of Use   
    </Link>   
    {""} of {""}  
    <Link className={`${styles.formLink}`} to="/">   
        smartshippingus.com{""}
    </Link>.   
    I also acknowledge and consent to the handling of my information by Smart Shipping Service® as outlined in the {""}  
    <Link className={`${styles.formLink}`} to="/">   
        Privacy Policy   
    </Link>.  
</p>
                  </Form.Group>

                  {/* Flex buttons with 50% width for previous and submit */}
                  <div
                    className="d-flex justify-content-between"
                    style={{ gap: "15px", marginTop: "2rem" }}
                  >
                    <Button
                      type="button"
                      onClick={previousStep}
                      className={`w-50 prevButton ${styles.prevButton}`}
                      variant="none"
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      className={`w-50 nextButton ${styles.nextButton}`}
                      variant="none"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Register;
