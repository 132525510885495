import { configureStore, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const loadState = () => {
    try {
      const serializedState = localStorage.getItem('authState');
      if (serializedState === null) {
        return { isLoggedIn: false, user: null, status: 'idle', error: null };
      }
      return JSON.parse(serializedState);
    } catch (err) {
      console.error("Could not load state", err);
      return { isLoggedIn: false, user: null, status: 'idle', error: null };
    }
  };
  const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('authState', serializedState);
    } catch (err) {
      console.error("Could not save state", err);
    }
  };  
// Async thunk for login
export const loginAsync = createAsyncThunk(
  'auth/loginAsync',
  async (loginData, thunkAPI) => {
    const response = await fetch('https://lobster-app-scymd.ondigitalocean.app/api/auth/local', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(loginData),
    });

    if (!response.ok) {
      throw new Error('Login failed. Please check your credentials.');
    }

    const {user,jwt} = await response.json(); // Assume the API returns user data

    return user;
  }
);


const authSlice = createSlice({
  name: 'auth',
  initialState: loadState(),
  reducers: {
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload;
      });
  },
});

// Save auth state whenever it changes
const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
  },
});

store.subscribe(() => {
  saveState(store.getState().auth);
});

export const { logout } = authSlice.actions;
export default store;