import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Table,
  Form,
  Dropdown,
  Button,
} from "react-bootstrap";
import styles from "./style.module.scss";
import { AiOutlineCar } from "react-icons/ai";
import { FaBoxOpen } from "react-icons/fa";
import { FaShippingFast } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";
import DepositModal from "./modal";
import { useSelector, useDispatch } from 'react-redux';

const WalletTabs = () => {
  const { isLoggedIn, user, status, error } = useSelector((state) => state.auth);
  const userEmail = user.email; // Ensure userEmail is defined
  const [tab2Data, setTab2Data] = useState([])
  const [balances, setBalances] = useState([]);
const [loading, setLoading] = useState(true); // State for loading
const [fetchError, setFetchError] = useState(null); // St

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://lobster-app-scymd.ondigitalocean.app/api/estimates?email=${userEmail}`); // Fetch data using user email from Strapi
        if (!response.ok) {
          throw new Error('Network response was not ok'); // Handle non-200 responses
        }
        const fetchedData = await response.json(); // Parse JSON response
       setTab2Data(fetchedData.data)
      } catch (error) {
        setFetchError(error); // Set error state
      } finally {
        setLoading(false); // Set loading to false after fetch
      }
    };

    fetchData(); // Call the fetch function
    fetchBalances()
  }, [userEmail]); // Add
  const fetchBalances = async () => {
    try {
      const response = await fetch(`https://lobster-app-scymd.ondigitalocean.app/api/balances?email=${userEmail}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json', // Optional, usually not needed for GET requests
        },
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "Failed to fetch balances.");
      }

      const {data} = await response.json();
      setBalances(data); // Assuming the response is an array of balances
    } catch (error) {
     
    } finally {
      setLoading(false);
    }
  };
  // Dummy data for tables
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options); // Adjust locale and options as needed
  };
  const mappedData = tab2Data?.map(item => ({
    id: item.id, // Added field
    shipmentType: item.shipmentType, // Added field
    originCountry: item.originCountry, // Added field
    destinationCountry: item.destinationCountry, // Added field
    containerType: item.containerType, // Added field
    originType: item.originType, // Added field
    destinationType: item.destinationType, // Added field

    height: item.height, // Added field
    length: item.length, // Added field
    makeModel: item.makeModel, // Added field
    mobility: item.mobility, // Added field
    quantity: item.quantity, // Added field
vgm: item.vgm, // Added field
width: item.width, // Added field
createdAt: formatDate(item.createdAt), // Added field
estimateStatus: item.estimateStatus, // Added field



    // Add any other fields that are part of your API response
  }));
 const  mappedBalance = balances.map(balance=>({
  id: balance.id, 
  paymentMethod:balance.depositMethod,
  amount:balance.amount,
  paymentStatus:balance.paymentStatus
  
 }))
  const tableData = {
    // tab1: {
    //   title: "INVENTORY",
    //   headers: ["ID", "Name", "Price"],
    //   data: [
    //     { id: 1, name: "Item 1", price: "$100" },
    //     { id: 2, name: "Item 2", price: "$200" },
    //   ],
    //   image: <AiOutlineCar size={30} />,
    //   subTabs: {
    //     subTab1: {
    //       headers: [
    //         "Inventory",
    //         "Date Rcvd",
    //         "Pkg",
    //         "Courier Trk",
    //         "Commodity",
    //         "Qty",
    //         "Dims (In)",
    //         "Gwt (lb)",
    //         "Shwt (lb)",
    //         "Value",
    //         "Condition",
    //         "Notes",
    //         "Contents",
    //         "Status",
    //       ],
    //       data: [
    //         { id: 1, name: "Sub Inventory 1 - Item 1", price: "$150" },
    //         { id: 2, name: "Sub Inventory 1 - Item 2", price: "$250" },
    //       ],
    //     },
    //     subTab2: {
    //       headers: [
    //         "Inventory",
    //         "Date Rcvd",
    //         "Category",
    //         "VIN Number",
    //         "Year",
    //         "Make",
    //         "Model",
    //         "Trim",
    //         "Body",
    //         "Value",
    //         "Condition",
    //         "Title",
    //         "Status",
    //       ],
    //       data: [
    //         { id: 1, name: "Sub Inventory 2 - Item 1", price: "$180" },
    //         { id: 2, name: "Sub Inventory 2 - Item 2", price: "$280" },
    //       ],
    //     },
    //   },
    // },
    tab2: {
      title: "SHIPMENTS",
      headers: [
       "Estimation No",
       "shipmentType",
"originCountry",
"destinationCountry",
"containerType",
"originType",
"destinationType",
"height",
"length",
"makeModel",
"mobility",
"quantity",
"vgm",
"width",
"createdAt",
"price",
"status"
      ],
      data: [
                { id: 1, name: "Sub Inventory 1 - Item 1", price: "$150" },
                { id: 2, name: "Sub Inventory 1 - Item 2", price: "$250" },
              ], 
      image: <FaBoxOpen size={30} />,
    },
    tab3: {
      title: "ESTIMATES",
      headers: [
        "Estimation No",
       "shipmentType",
"originCountry",
"destinationCountry",
"containerType",
"originType",
"destinationType",
"height",
"length",
"makeModel",
"mobility",
"quantity",
"vgm",
"width",
"createdAt",
"status"
    
      ],
      data: [
        ...mappedData
      ],
      image: <FaShippingFast size={30} />,
    },
    tab4: {
      title: "WALLET",
      headers: [
        "Reference No",
        "Payment Method",
        "Amount",
        "Status",
      ],
      data: [
       ...mappedBalance
      ],
      image: <FaWallet size={30} />,
    },
  };

  const [activeTab, setActiveTab] = useState("tab1");
  const [activeSubTab, setActiveSubTab] = useState("subTab1");

  const handleSubSelect = (key) => {
    setActiveSubTab(key);
  };

  const handleSelect = (key) => {
    setActiveTab(key);
  };
  const approvedBalanceTotal = mappedBalance.reduce((total, balance) => balance.paymentStatus === "approved" ? total + parseFloat(balance.amount) : total, 0);
console.log('approvedBalanceTotal',approvedBalanceTotal)
  return (
    <>
      <section className={`WalletTabs ${styles.WalletTabs}`}>
        {/* <Container className={`${styles.container}`}> */}
        <Row className={`${styles.row}`}>
          <Col xs={12} md={12} lg={12}>
            <Tab.Container defaultActiveKey="tab2" onSelect={handleSelect}>
              <Nav variant="tabs" className={styles.customNavTabs}>
                {/* <Nav.Item>
                  <Nav.Link eventKey="tab1">
                    {tableData.tab1.image} {tableData.tab1.title}
                  </Nav.Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link eventKey="tab2">
                    {tableData.tab2.image} {tableData.tab2.title}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab3">
                    {tableData.tab3.image} {tableData.tab3.title}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab4">
                    {tableData.tab4.image} {tableData.tab4.title}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                {/* Main Inventory Tab with sub-tabs */}
                {/* <Tab.Pane eventKey="tab1">
                  <Tab.Container
                    defaultActiveKey="subTab1"
                    onSelect={handleSubSelect}
                  >
                    <Nav variant="pills" className="my-3">
                      <Nav.Item>
                        <Nav.Link eventKey="subTab1">Parcels</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="subTab2">Automobile</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="subTab1">
                        <TableHeaderWithControls activeTabTitle="Parcels" />
                        <DynamicTable
                          headers={tableData.tab1.subTabs.subTab1.headers}
                          data={tableData.tab1.subTabs.subTab1.data}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="subTab2">
                        <TableHeaderWithControls activeTabTitle="Automobile" />
                        <DynamicTable
                          headers={tableData.tab1.subTabs.subTab2.headers}
                          data={tableData.tab1.subTabs.subTab2.data}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </Tab.Pane> */}

                {/* Other Main Tabs */}
                <Tab.Pane eventKey="tab2">
                  <TableHeaderWithControls
                    activeTabTitle={tableData[activeTab]?.title}
                  />
                  <DynamicTable
                    headers={tableData.tab2.headers}
                    data={tableData.tab2.data}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="tab3">
                  <TableHeaderWithControls
                    activeTabTitle={tableData[activeTab]?.title}
                  />
                  <DynamicTable
                    headers={tableData.tab3.headers}
                    data={tableData.tab3.data}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="tab4">
                  <TableHeaderWithControls
                  approvedBalanceTotal={approvedBalanceTotal}
                  fetchBalances={fetchBalances}
                    activeTabTitle={tableData[activeTab]?.title}
                  />
                  <DynamicTable
                    headers={tableData.tab4.headers}
                    data={tableData.tab4.data}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
        {/* </Container> */}
      </section>
    </>
  );
};

// Component to display the search and pagination controls
const TableHeaderWithControls = ({ activeTabTitle,approvedBalanceTotal,fetchBalances }) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openBalance, setOpenBalance] = useState(0.0);
  const [availableCredit, setAvailableCredit] = useState(0.0);

  // State for controlling the modal visibility
  const [showModal, setShowModal] = useState(false);

  const handleSelect = (value) => {
    setRowsPerPage(value);
    console.log(`Selected: ${value} rows per page`);
  };

  const handleCloseModal = () => setShowModal(false); // Close the modal
  const handleShowModal = () => setShowModal(true); // Open the modal

  // Update balances when the "WALLET" tab is active
  useEffect(() => {
    if (activeTabTitle === "WALLET") {
      setOpenBalance(500.0);
      setAvailableCredit(300.0);
    } else {
      setOpenBalance(0.0);
      setAvailableCredit(0.0);
    }
  }, [activeTabTitle]);

  return (
    <>
      {/* Modal for Add Deposit */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Wallet</h2>
        <div>
          {activeTabTitle === "WALLET" && (
            <DepositModal show={showModal} handleClose={handleCloseModal} fetchBalances={fetchBalances} />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center my-3">
        {/* Pagination Dropdown */}
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {`${rowsPerPage} rows`}
          </Dropdown.Toggle>
          <span className="mx-2">Show entries</span>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleSelect(10)}>10</Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelect(25)}>25</Dropdown.Item>
            <Dropdown.Item onClick={() => handleSelect(50)}>50</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Wallet-specific details and button */}
        {activeTabTitle === "WALLET" && (
          <div
            className="d-flex justify-content-between"
            style={{ width: "50%" }}
          >
            <div>
              <strong>Open Balance:</strong> ${openBalance.toFixed(2)}
            </div>
            <div>
              <strong>Available Credit:</strong> ${approvedBalanceTotal}
            </div>
            {/* <Button
            type="button"
            className={`primary ${styles.button}`}
            variant="none"
            onClick={handleShowModal} // Open modal on button click
          >
            + Add Deposit
          </Button> */}
          </div>
        )}

        {/* Search input */}
        <div className="d-flex align-items-center">
          <Form.Label className="mr-2 mb-0">Search:</Form.Label>
          <Form.Group controlId="searchInput" className="mb-0">
            <Form.Control type="text" placeholder="Search" />
          </Form.Group>
        </div>
      </div>
    </>
  );
};

// Component to display dynamic table data and headers
const DynamicTable = ({ headers, data }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index}>
            {Object.values(item).map((value, i) => (
              <td key={i}>{value}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default WalletTabs;
