import React, { useState } from "react";
import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";
import styles from "./style.module.scss";
import { useSelector, useDispatch } from 'react-redux';

const DepositModal = ({fetchBalances}) => {
  const { isLoggedIn, user, status } = useSelector((state) => state.auth);

  // State to control the modal visibility
  const [show, setShow] = useState(false);
  const [existingCredit, setExistingCredit] = useState("");
  const [depositMethod, setDepositMethod] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [senderName, setSenderName] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const handleClose = () => {
    // Reset form values on close
    setDepositMethod("");
    setDepositAmount("");
    setSenderName("");
    setFile(null);
    setError("");
    setShow(false);
    
  };
  async function uploadFile(file) {
    const formData = new FormData();
    formData.append('files', file); // Appending the file
  
    const response = await fetch("https://lobster-app-scymd.ondigitalocean.app/api/upload", {
      method: "POST",
      body: formData,
    });
  
    if (!response.ok) {
      throw new Error("Failed to upload the file.");
    }
  
    // Get the file information (including URL or ID)
    const fileData = await response.json();
    return fileData[0]; // Return the first file (Strapi returns an array)
  }
  
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      console.log(selectedFile)
      if (fileType === "image/jpeg" || fileType === "image/png") {
        setFile(selectedFile);
        setError(""); // Clear any previous error
      } else {
        setError("Only JPG and PNG files are allowed.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fileData = await uploadFile(file); // file is your selected file
    // Validate inputs
    if (  !depositMethod || !depositAmount  || !file) {
      setError("All fields are required.");
      return;
    }

    // formData.append("existingCredit", existingCredit);

    // Create the data object to send
  // Create a new FormData object
 
    try {
      const response = await fetch("https://lobster-app-scymd.ondigitalocean.app/api/balances", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({data:{
          depositMethod,
          amount: depositAmount,
          slip: fileData.id, // Use the file ID or URL from the upload response
      
            user:user.id
          , // Send the user ID (or any other relevant user data)

        }}),
      });
  
    

      if (!response.ok) {
        throw new Error("Failed to submit the form.");
      }

      const result = await response.json();
      console.log("Form submitted successfully:", result);
      handleClose(); // Close modal after successful submission
      fetchBalances()
    } catch (error) {
      setError(error.message);
    }
  };
  // Modal open/close handlers
  const handleShow = () => setShow(true);

  // Submit handler for the form
  
  return (
    <>
      {/* Add Deposit Button */}
      <Button
        type="button"
        className={`primary ${styles.button}`}
        variant="none"
        onClick={handleShow}
      >
        + Add Deposit
      </Button>

      {/* Modal for Deposit Form */}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Deposit</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            {error && <div className="alert alert-danger">{error}</div>} {/* Display error message */}
        

            <Form.Group controlId="depositMethod" className="mb-3">
              <Form.Label>Deposit Method</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter deposit method"
                value={depositMethod}
                onChange={(e) => setDepositMethod(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="depositAmount" className="mb-3">
              <Form.Label>Deposit Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter deposit amount"
                value={depositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
              />
            </Form.Group>

        

            <Form.Group controlId="uploadImage" className="mb-3">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default DepositModal;
